<template>
  <div class="login-wrap">
    <el-card class="box-card">
      <div class="row-box">
        <div class="lf-box">
          <img src="../../public/img/icons/logo.png" alt="">
          <p>{{title}}</p>
        </div>
      <el-form :model="loginForm" :rules="rules" ref="loginForm"  class="rg-form">
				<el-form-item prop="itcode">
          <el-input placeholder = '请输入用户名' v-model="loginForm.itcode" prefix-icon="el-icon-user"></el-input>
				</el-form-item>
				<el-form-item prop="password" v-if="!forgetPassWord">
          <el-input placeholder = '请输入密码' show-password v-model="loginForm.password" prefix-icon="el-icon-lock"></el-input>
				</el-form-item>
				<el-form-item prop="email" v-if="forgetPassWord">
          <el-input placeholder = '请填写注册邮箱' v-model="loginForm.email" prefix-icon="el-icon-message"></el-input>
				</el-form-item>
				<el-form-item prop="code" >
					<div class="yzkbox-box">
						<el-input :maxlength="code.length" placeholder = '请输入验证码' v-model="loginForm.code"></el-input>
						<p @click="onRefrashCode(2)" v-loading="getCodeLoading">
							<i v-if="!codeError && loginForm.code && codeSucess" class="code-ok"></i>
							<span v-if="coreError" class="error-code">验证码失败</span>
							<span v-else v-for="(code, i) in codeList" :key="i" :style="getCodeStyle(code)">{{code.code}}</span>
						</p>
						<i v-if="codeError && !codeSucess && loginForm.code" class="code-error">*验证码错误</i>
          </div>
				</el-form-item>
				<el-form-item>
					<p class="checkbox-box" v-if="!forgetPassWord">
						<!-- <el-checkbox v-model="loginForm.save">记住密码</el-checkbox> -->
						<el-button @click="onForgetPassWord" type="text">忘记密码</el-button>
          </p>
				</el-form-item>
				<el-form-item>
          <el-button :loading="loading" type="primary" @click="onSubmit" style="width:100%;">{{forgetPassWord?'重置密码':'登录'}}</el-button>
				</el-form-item>
				<p v-if="forgetPassWord" class="back-box">
          <el-button @click="onForgetPassWord" type="text">返回登录</el-button>
				</p>
      </el-form>
			</div>
    </el-card>
	<div style="text-align:center;margin:0 auto; padding:20px 0;position: absolute;left: 0;right: 0; bottom:0;">
         <a target="_blank" href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-top:12px;">
			 <img src="../../public/img/icons/beiAn.png" style="float:left;"/>
		 	<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京ICP备17060793号-12</p>
		 </a>
		   <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033741" style="margin-left:6px;display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-top:12px;">
			 <img src="../../public/img/icons/beiAn.png" style="float:left;"/>
		 	<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010802033741号</p>
		 </a>
       </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Card, Input } from 'element-ui'
import { mapMutations } from 'vuex'
import { getLoginCode, edcLogin, forgetPwd} from '../api/login' 
export default {
	name:'login',
  components: {
    [Card.name]: Card,
    [Input.name]: Input
  },
  data () {
    return {
      title: '临床研究数据管理平台',
      loginForm: {
        save: false,
        itcode: '',
				password: '',
				email:'',
				code:''
			},
			rules:{
			},
			code:[],
			loading: false,
			forgetPassWord: false,
			codeError: false,
			codeSucess:false,
			getCodeLoading:true,
			codeKey:'',
			coreError:false
    }
	},
	computed:{
		codeList(){
			let arr = []
			this.code.map((e)=>{
				arr.push({
					code:e,
					color:this.getRandomColor(),
					fontSize:`1${[Math.floor(Math.random()*6+4)]}px`,
					transform:`rotate(${Math.floor(Math.random()*65)-Math.floor(Math.random()*20)}deg)`
				})
			})
			return arr
		}
	},
	watch:{
		'loginForm.code'(n){
				if (n.length === this.code.length) {
					this.validateCode()
				}else{
					this.codeError = false
					this.codeSucess = false
				}
			}
			
	},
  created () {
		// this.getCoolie()
		this.onRefrashCode(1)
  },
  methods: {
		... mapMutations(['setIsLogin', 'setUserData','setPasswordTimeOut']),
		onRefrashCode(v){
			this.getCodeLoading = true
			this.code = []
			getLoginCode({}).then((res)=>{
				this.getCodeLoading = false
					if (res.code == 200) {
							this.codeKey = res.data.codeKey
							this.code = res.data?.code.split('')
							this.coreError = false
							this.validateCode()
					} else {
							this.code = []
							this.coreError = true
					}
			}).catch((err)=>{
				this.code = []
				this.coreError = true
				this.getCodeLoading = false
			})
			
		},
		onForgetPassWord(){
			this.$refs['loginForm'].clearValidate()
			this.forgetPassWord = !this.forgetPassWord
		},
		getCodeStyle(c){
			return `color:${c.color}; font-size:${c.fontSize};transform:${c.transform}`
		},
		 //颜色对象
		getRandomColor(){
			return `rgba(${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)} ,0.8)`
		},
		validateCode(){
			if (this.code.join().replace(/,/g,'').toLowerCase() == this.loginForm.code.toLowerCase()) { // 验证码
					this.codeError = false
					this.codeSucess = true
				}else {
					this.codeError = true
					if(this.loginForm.code.length == this.code.length){
						this.codeSucess = false
					}else {
						this.codeError = true
						this.codeSucess = false
					}
				}
		},
    onSubmit () {
			this.validateCode()
				if (!this.forgetPassWord) {
					if (this.loginForm.itcode && this.loginForm.password && this.loginForm.code) {
						this.loading = true
						edcLogin({itcode: this.loginForm.itcode, password: this.loginForm.password, code: this.loginForm.code, codeKey: this.codeKey})
						 .then((res) => {
							this.loading = false
							if (res.code == 210) {
								this.saveToken(res.data, 200)
								this.$router.replace('/')
								this.messageToast(res.msg,'error')
							}else if (res.code == 206 || res.code == 207 ) {
								localStorage.setItem('dialogClose', 1)
								this.saveToken(res.data, 206)
								this.$router.replace('/')
							} else if (res.code == 200) {
								this.saveToken(res.data, 200)
								// this.$router.go(-1)
								this.$router.replace('/')
								// this.messageToast(res.msg,'success')
							}else	if (res.code == 209 ) {
								this.onRefrashCode(1)
								this.messageToast(res.msg,'error')
								this.loading = false
								return
							}else{
								this.loading = false
								this.messageToast(res.msg,'error')
								return
							}
							if (this.loginForm.save) {
								this.setCookie(this.loginForm.itcode,this.loginForm.password,7)
							}else {
								this.delCookie()
							}
						}).catch((err) => {
							console.log(err)
							this.loading = false
						})
					} else {
						this.errorMessageToast('请输入正确的用户名,密码,验证码')
					}
				} else {
					this.loading = true
					if (this.loginForm.itcode && this.loginForm.email&& this.loginForm.code) {
						forgetPwd({email:this.loginForm.email, itcode: this.loginForm.itcode}).then((res)=>{
							if (res.code == 200) {
								this.messageToast(res.msg,'success')
							}else {
								this.messageToast(res.msg,'error')
							}
						}).catch((err)=>{
							console.log(err);
						})
						this.loading = false
					} else {
						this.$message({
							message: '请输入正确的用户名,邮箱,验证码',
							type: 'error'
						})
						this.loading = false
					}
				}
		},
		messageToast(msg,type){
			this.$message({
				message:msg,
				type:type
			})
		},
		errorMessageToast(message){
			if (!this.codeSucess) {
				this.$message({
					message: message,
					type: 'error'
				})
			}
		},
		saveToken(v,code){
			localStorage.setItem('token',v.token)
			let obj = { loginName:v.user.userItcode, userItCode: v.user.userItcode, userPhone: v.user.userPhone, userEmail: v.user.userEmail, userName: v.user.userName, roleName: v.user.roleName,}
			localStorage.setItem('userData', JSON.stringify(obj))
			let arr = v.funcList.length && v.funcList.map((e)=>{
				return e.funcCode
			})
			localStorage.setItem('userMenu_obj', JSON.stringify(arr))
			this.setUserData(obj)
			if (code === 200) {
				localStorage.setItem('passwordTimeOut',0)
				this.setPasswordTimeOut(0)
			}else {
				this.setPasswordTimeOut(1)
				localStorage.setItem('passwordTimeOut',1)
			}
			
		},
    setCookie (name, password, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      var expires = 'expires=' + d.toUTCString()
      const user = {
        name: name,
        password: password
      }
      document.cookie = `user=${JSON.stringify(user)};${expires};path=/`
    },
    getCoolie () {
			const cookie = document.cookie
      if (cookie.length) {
        const user = JSON.parse(cookie.split('=')[1])
        this.loginForm.itcode = user ? user.name : ''
        this.loginForm.password = user ? user.password : ''
      }
    },
    delCookie () {
      var date = new Date()
      date.setTime(date.getTime() - 10000)
      const user = document.cookie.split('=')[0]
      if (user) {
        document.cookie = user + '=null; expire=' + date.toGMTString() + '; path=/'
      }
    }
	}
}
</script>

<style lang="scss" scoped>
$theme-clor:rgb(35,53,75);
@mixin flexistyle($fl:flex, $justf:center, $ali: center){
    display: $fl;
    justify-content: $justf;
    align-items: $ali;
  }
.login-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: url('../../public/img/icons/login-bg.jpg');
  @include flexistyle( );
  background-size: cover;
  flex-direction: column;
  .box-card {
    background-color: rgba(255,255,255,.5);
    border: none;
    /deep/ .el-card__body {
      padding: 0;
    }
    @include flexistyle( );
    .row-box {
      display: flex;
      .lf-box {
        width: 290px;
        height: 100%;
        display: flex;
				padding-top: 15%;
        align-items: center;
        font-size: 33px;
        color: #fff;
        font-weight: 900;
        flex-direction: column;
        img {
					width: 75%;
					padding-bottom: 20px;
				}
				p{
					font-size: 22px;
				}
      }
      .rg-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #fff;
				padding: 20px 40px;
				padding-top: 40px;
				justify-content: center;
        >p {
          // font-size: 30px;
          width: 100%;
        }
        .input-box {
          padding: 5px 0;
          text-align: center;
        }
        .checkbox-box {
					display: flex;
					justify-content: space-between;
					padding: 12px 0 20px;
					align-items: center;
					p{
						font-size: 14px;
					}
        }
      }
    }
    img {
      height: 100px;
    }
.login-btn{
  width: 400px;
  padding-top: 40px;
   @include flexistyle(flex, center, none );
}
	}
	.yzkbox-box {
	 /deep/	.el-loading-spinner .circular {
		width: 30px;
		height: 30px;
		color: #ccc;
	}
		margin-top: 6px;
		display: flex;
		position: relative;
		p {
			margin-left: 8px;
			width: 80px;
			min-height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			font-weight: 800;
			cursor: pointer;
			position: relative;
			span{
				padding: 0 4px;
			}
			.code-ok {
				position: absolute;
				width: 20px;
				height: 20px;
				background: url('../../public/img/icons/ok.svg') no-repeat;
				background-size: contain;
				left: -40px;
			}
		}
		.error-code {
			font-size: 12px;
			font-weight: normal;
			color: #F56C6C;
			word-break: keep-all;
		}
		.code-error {
			position: absolute;
			left: 0;
			bottom: -36px;
			color: #F56C6C;
			font-size: 12px;
		}
	}
	.back-box {
		display: flex;
		justify-content: center;
		align-items: center;
		/deep/ span{
			padding-bottom: 3px;
			border-bottom: 1px solid #409EFF;
		}
	}
}
</style>
