import request from './axios'

export function getLoginCode(params) { //
    return request({
        url: '/api/base/getLoginCode',
        method: 'GET',
        params,
    })
}
export function edcLogin(params) { //
    return request({
        url: '/api/base/edcLogin',
        method: 'POST',
        data:params,
    })
}
export function forgetPwd(params) { //
    return request({
        url: '/api/base/forgetPwd',
        method: 'POST',
        data:params,
    })
}
